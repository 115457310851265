import { useMemo } from 'react'
import { GalleryVariantProps, gallery } from './gallery.variants'

export type useGalleryProps = GalleryVariantProps & {
  className?: string
  href?: string
  title?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  galleryId?: string
  data: {
    id: string
    image: string
  }[]
}

export const useGallery = (props: useGalleryProps) => {
  const {
    className = '',
    galleryId = 'gallery',
    href,
    target,
    title,
    data = []
  } = props

  const styles = useMemo(() => gallery(), [])

  return {
    className,
    styles,
    galleryId,
    href,
    target,
    title,
    data
  }
}
